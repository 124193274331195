.input-css::placeholder,
.input-css::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.wrapper {
    position:relative;
    background-color: rgba(255, 255, 255, 0.05);
    margin-bottom: 30px;
    display: flex;
    border-radius: 3px;
    align-items: center;
}

.iconMail,
.iconPassword,
.eyePassword {
    height: 30px;
    width: 30px;
    padding: 4px;
    box-sizing:border-box;
    margin: 0 5px;
}
  
.eyePassword {
   cursor: pointer;
}

#validation {
    width: 350px;
}

.FormLogin-ConfirmMail {
    color: #fff;
    text-align: center;
}

.FormLogin-ConfirmMail p {
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
    line-height: 1.3em;
}

input {
	display: block;
}

.btn-resendMail {
    background: #00B0FF;
    color: black;
    border-style: outset;
    border-color: #00B0FF;
    height: 38px;
    width: 100%;
    text-shadow: none;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 2px 2px rgba(0, 176, 255, 0.2);
    cursor: pointer;
    margin-top: 30px;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
}

.btn-verifyMail {
    background: #00B0FF;
    color: black;
    border-style: outset;
    border-color: #00B0FF;
    height: 38px;
    width: 100%;
    text-shadow: none;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 2px 2px rgba(0, 176, 255, 0.2);
    cursor: pointer;
    margin-top: 10px;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
}

.input-css {
    width: 100%;
    height: 38px;
    border-style: hidden;
    padding: 5px;
    color: #fff;
    background: transparent;
}

.a-page-validation {
    background: #CBCBCB;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.linha {
    height: 100vh;
    position: relative;
    flex-flow: row wrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    z-index: 1;
}

.coluna-A {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coluna-B {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-black {
    position: absolute;
    width: 53vw;
    height: 115vh;
    top: -5%;
    right: -3%;
    background-color: black;
    transform: rotate(5deg);
    background: #252525;
    box-shadow: 6px -3px 25px 15px rgba(0, 0, 0, 0.3);
}

.LoginPageImage {
    width: 60%;
}

.LoginLogoImage {
    display: flex;
    text-align: center;
    margin-bottom: 60px;
}

fieldset {
    border-top: 1px solid #FFFF;
    border-bottom: none;
    border-left: none;
    border-right: none;
    display: block;
    text-align: center;
    color: grey;
}
 
fieldset legend {
    padding: 0px 10px;
}

.RecuperarSenha {
    color: grey;
    text-align: center;
}

.RecuperarSenha span,
.Registrar span {
    color: #00B0FF;
    cursor: pointer;
    margin-left: 10px;
}

.Registrar {
    color: grey;
    text-align: center;
}

.LineSeparator {
    margin: 25px 0;
}

.checkbox {
    border-radius: 100%;
    background-color: white;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.LoginPageImageFinishvalidation {
    width: 438px;
    height: 438px;
}

.FormLogin-FinishRegister p {
    color: #fff;
    text-align: center;
    width: 84%;
    margin-bottom: 30px;
    margin-left: 30px;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;
    line-height: 1.3em;
}

.btn-enter {
    background: #00B0FF;
    color: black;
    border-style: outset;
    border-color: #00B0FF;
    height: 38px;
    width: 100%;
    text-shadow: none;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 2px 2px rgba(0, 176, 255, 0.2);
    cursor: pointer;
    font-weight: 600;
    font-family: "Montserrat";
    font-style: normal;   
}

.FormLogin-ConfirmMail p.cooldownText {
    font-family: "Montserrat";
    color: #fff;
    text-align: center;
    font-size: 14px;
}

@media (max-width: 1150px) {
    .a-page-validation .background-black {
        right: -4%;
    }
}

@media (max-width: 920px) {
    .a-page-validation {
        background-color: #252525;
        overflow: visible;
    }

    .LoginLogoImage {
        margin-bottom: 30px;
    }

    .a-page-validation .coluna-A {
        display: none;
    }

    .a-page-validation .coluna-B {
        width: 100%;
    }
    
    .a-page-validation .background-black {
        display: none;
    }
}

@media (max-width: 375px) {
    #validation {
        width: 300px;
    }

    .LoginPageImage {
        width: 100%;
    }
}