.a-page-downloadApp {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #252525;
    overflow: hidden;
}

.a-page-downloadApp .a-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.a-page-downloadApp .a-content .content {
    width: 300px;
}

.a-page-downloadApp .a-content .content .logo {
    margin: 0 10px 25px;
}

.a-page-downloadApp .a-content .content .logo img {
    width: 100%;
}

.a-page-downloadApp .a-content .content .title {
    padding: 9px;
}

.a-page-downloadApp .a-content .content .title h1 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 38px;
    line-height: 48.76px;
    color: #fff;
    text-align: right;
}

.a-page-downloadApp .a-content .content .title h3 {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 38px;
    line-height: 48.76px;
    color: #fff;
    text-align: right;
}

.a-page-downloadApp .a-content .content .description {
    margin-top: 20px;
}

.a-page-downloadApp .a-content .content .description p {
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 25px;
    line-height: 32.91px;
    color: #fff;
}

.a-page-downloadApp .a-footer {
    position: absolute;
    bottom: 0;
    height: 160px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.a-page-downloadApp .a-footer .content {
    position: relative;
    z-index: 1;
    width: 300px;
}

.a-page-downloadApp .a-footer .content .title {
    margin-bottom: 10px;
}

.a-page-downloadApp .a-footer .content .title h4 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 18px;
    line-height: 34.38px;
    color: #252525;
}

.a-page-downloadApp .a-footer .content .shop-list {
    display: flex;
    justify-content: space-between;
}

.a-page-downloadApp .a-footer .content .shop-list > div {
    width: 48%;
}

.a-page-downloadApp .a-footer .content .shop-list div img {
    width: 100%;
}

.a-page-downloadApp .a-footer .footer-background {
    background-color: #CBCBCB;
    position: absolute;
    height: 130%;
    width: 120%;
    bottom: -40%;
    left: -10%;
    transform: rotate(8deg);
    box-shadow: inset 6px -3px 25px 15px rgba(0, 0, 0, 0.3);
}