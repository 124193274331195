.rulePassword__content {
  width: 100%;
  margin: 25px 0;
}

.rulePassword__content .title,
.rulePassword__content span {
  font-weight: 400;
  font-family: "Montserrat";
  color: #888888;
}

.rulePassword__content .title {
  font-weight: 600;
  margin-bottom: 10px;
}

.rulePassword__content .item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.rulePassword__content .item:last-child {
  margin-bottom: 0;
}

.rulePassword__checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2px solid #333333;
}

.rulePassword__checkbox.checked {
  border-color: #08783A;
}

.rulePassword__checkbox img {
  display: none;
}

.rulePassword__checkbox.checked img {
  display: block;
}