.linha-Initial {
    width: 100%;
    height: 90px;
    background-color: #00B0FF;
    justify-content: space-between;
    display: flex;
}

.user-info p {
    font-family: "Montserrat";
    text-align: right;
}

.user-info .user-email {
    font-weight: 400;
    font-size: 13px;
}

.user-info .user-name {
    font-weight: 600;
    font-size: 20px;
}

.main-page {
    min-height: 100vh;
    background-color: #252525;
}

.dropdown-content {
    position: absolute;
    top: 105px;
    right: 15px;
    width: 260px;
    overflow: hidden;
    transition: ease 0.2s all;
    z-index: 9;
}

.dropdown-content p {
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 16px;
    text-align: center;
}

.initialPage-btn-panel,
.initialPage-btn-redefine-password {
    display: flex;
    position: relative;
    background-color: #00B0FF;
    border-radius: 3px;
    height: 36px;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.initialPage-btn-logout
{
    display: flex;
    position: relative;
    background-color: #DD2B2B;
    border-radius: 3px;
    height: 36px;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.initialPage-btn-panel .addNew-icon
{
    padding: 0 10px;
    width: 20px;
    position: absolute;    
}

.keyIcon,
.logoutIcon {
    padding: 0 10px;
    width: 24px;
    position: absolute;
}

.navbar-right,
.navbar-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-right .initialPage-btn-redefine-password p,
.navbar-right .initialPage-btn-panel p {
    color: #252525;
}

.navbar-right p {
    font-size: 20px;
    color: white;
    width: 100%;
}

.profile-picture {
    margin: 0 20px;
    border-radius: 100px;
    cursor: pointer;
    width: 70px;
    height: 70px;
}

.SmallLogoInitial {
    margin-left: 117px;
    background-color: #00B0FF;
}

.body-left,
.body-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-weight: 600;
    font-family: "Montserrat";
}

.a-body-right-buy
{
    width: 410px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.a-body-right-buy > p {
    color: #FFFF;
    text-align: center;
    font-size: 24px;
    margin-bottom: 100px;
    line-height: 1.3em;
}

.a-body-right-buy .btnShop {
    width: 200px;
    height: 50px;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 20px;
    box-sizing: border-box;
}

.initial_a-container {
    margin-bottom: 300px;
}

.a-body-right-buy .btnShop p
{
    color: #424242;
    font-size: 20px;
    width: 100%;
    text-align: center;
}

.initial_a-content
{
    display: flex;
    justify-content: center;
}

.initial_content-hasBook
{
    width: calc((210px * 8) - 10px);
    position: relative;
    margin-top: 30px;
}

.initial_content-noHasBook
{
    display: flex;
    flex-direction: row;
    width: 90%;
    height: calc(100vh - 90px);
}

.initial_content-hasBook h1
{
    padding: 20px 0;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat";
}

.initial_content-search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.initial_content-search .input-component {
    background-color: rgb(0 0 0 / 10%);
    border-radius: 20px;
    width: 100%;
}

.initial_content-search .input-component input::placeholder {
    color: #e7e7e7;
}

.initial_box
{
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 200px;
    height: 300px;
    overflow: hidden;
}

.initialpage-internal-buttons .book-buttons
{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 80%);
    flex-direction: column;
    z-index: 2;
}

.initialpage-internal-buttons .book-buttons > div
{
    display: flex;
    height: 38px;
    width: 172px;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
}

.initialpage-internal-buttons .book-buttons > div:first-child 
{
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 15px;
    margin-bottom: 10px;
}

.initialpage-internal-buttons .book-buttons .edit-button
{
    background-color: #00B0FF;
    top: 100px;
    cursor: pointer;
}

.initialpage-internal-buttons .book-buttons .delete-button
{   
    background-color: #DD2B2B;
    top: 145px;
    cursor: pointer;
}

.initialpage-internal-buttons .book-buttons .edit-button, .delete-button p 
{
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 15px;
    text-align: center;
}

.initialpage-internal-buttons .arrow-options
{
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    z-index: 3;
    cursor: pointer;
    padding: 10px;
    transition: ease 0.2s all;
}

.initialpage-internal-buttons .arrow-options.arrow-up
{
    transform: rotate(180deg)
}

.initial_box .circle
{
    position: absolute;
    top: -5px;
    right: -5px;
    box-shadow: 0px 0px 30px 50px rgba(0,0,0,0.70);
}

.initial_box:nth-child(8n) {
    margin-right: 0;
}

.initial_box > img
{
    width: 200px;
    height: 300px;
    cursor: pointer;
}

.initial_box .flag
{
    position: absolute;
    right: 0;
    bottom: 20px;
    display: flex;
    align-items: center;
}

.initial_box .flag p
{
    color: white;
    z-index: 1;
    position: absolute;
    right: 5px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Montserrat";
}

.initial_a-information-download-app
{
    position: fixed;
    bottom: -50px;
    width: 100%;
    height: 50px;
    background-color: #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    z-index: 9;
}

.initial_a-information-download-app h5
{
    margin-right: 20px;
    color: #252525;
    font-weight: 600;
    font-family: "Montserrat";
}

.initial_a-information-download-app .a-download
{
    display: flex;
}

.initial_a-information-download-app .a-download a
{
    display: inline-flex;
}

.initial_a-information-download-app .a-download a:first-child
{
    margin-right: 15px;
}

.initial_a-information-download-app .a-download a img
{
    height: 40px;
}

.initial_a-information-download-app .information-download-close
{
    position: absolute;
    right: 25px;
    display: flex;
    cursor: pointer;
}

.initial_a-information-download-app .information-download-close img
{
    width: 15px;
}

.purchase__rounded {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: #00B0FF;
    position: fixed;
    bottom: 130px;
    right: 115px;
    z-index: 9;
}

.purchase__rounded a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.purchase__rounded a img {
    width: 38px;
}

@media (max-width: 1680px) {
    .initial_content-hasBook {
        width: calc((210px * 7) - 10px);
    }

    .initial_box
    {
        margin-right: 10px;
    }
    
    .initial_box:nth-child(7n) {
        margin-right: 0;
    }
}

@media (max-width: 1440px) {
    .initial_content-hasBook {
        width: calc((210px * 6) - 10px);
    }
    
    .initial_box {
        margin-right: 10px;
    }
    
    .initial_box:nth-child(6n) {
        margin-right: 0;
    }
}

@media (max-width: 1200px)
{
    .initial_content-hasBook {
        width: calc((210px * 5) - 10px);
    }
    
    .initial_box {
        margin-right: 10px;
    }
    
    .initial_box:nth-child(5n) {
        margin-right: 0;
    }
}

@media (max-width: 1050px)
{
    .initial_content-hasBook {
        width: calc((210px * 4) - 10px);
    }
    
    .initial_box {
        margin-right: 10px;
    }
    
    .initial_box:nth-child(4n) {
        margin-right: 0;
    }
}
