#alert
{
    position: fixed;
    top: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    /* display: flex; */
    display: none;
    align-items: center;
    justify-content: center;
    transition: ease 0.3s all;
}

#alert .a-content
{
    width: 40%;
    min-width: 300px;
    background-color: #fff;
    padding: 20px;
    border-radius: 3px;
}

#alert .a-title h1
{
    font-size: 20px;
    font-family: 'Montserrat';
    margin-bottom: 20px;
    font-weight: 600;
    color: #333;
}

#alert .a-message p
{
    font-size: 16px;
    font-family: 'Montserrat';
    color: #333;
    font-weight: 400;
}

#alert .a-buttons
{
    display: flex;
    justify-content: flex-end;
}

#alert .a-buttons
{
    margin-top: 25px;
}

#alert .a-buttons .alert-button
{
    margin-left: 15px;
    padding: 5px 8px;
    cursor: pointer;
}

#alert .a-buttons .alert-button p
{
    font-family: 'Montserrat';
    font-weight: 500;
}

@media (max-width: 375px) {
    #alert .a-content
    {
        width: 40%;
        min-width: 250px;
    }
}